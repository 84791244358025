<template>
    <section class="c-dashboard">
        <app-intro>
            <h1>Configuraties</h1>
            <p>Opgeslagen configuraties</p>
        </app-intro>

        <transition-group class="c-grid-list" name="fade" tag="section">
            <div key="configurations-table" class="table table--bordered" v-if="configurations.length > 0">
                <div class="table__thead">
                    <div class="table__tr" v-for="configuration in configurations" :key="'thead-' + configuration.id">
                        <div class="table__th table__th--order" @click="orderTable('name')">
                            Naam
                            <svg v-show="order.name === false" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                            <svg v-show="order.name === 'desc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                            <svg v-show="order.name === 'asc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                        </div>
                        <div class="table__th table__th--order" @click="orderTable('company')">
                            Bedrijfsnaam
                            <svg v-show="order.name === false" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                            <svg v-show="order.name === 'desc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                            <svg v-show="order.name === 'asc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                        </div>
                        <div class="table__th table__th--order" @click="orderTable('style')">
                            Stijl
                            <svg v-show="order.style === false" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                            <svg v-show="order.style === 'desc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                            <svg v-show="order.style === 'asc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                        </div>
                        <div class="table__th table__th--order" @click="orderTable('status')">
                            Status
                            <svg v-show="order.status === false" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                            <svg v-show="order.status === 'desc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                            <svg v-show="order.status === 'asc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                        </div>
                        <div class="table__th table__th--order" @click="orderTable('price')">
                            Prijs
                            <svg v-show="order.price === false" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                            <svg v-show="order.price === 'desc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                            <svg v-show="order.price === 'asc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                        </div>
                        <div class="table__th table__th--order" @click="orderTable('date')">
                            Datum
                            <svg v-show="order.date === false" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                            <svg v-show="order.date === 'desc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                            <svg v-show="order.date === 'asc'" width="10" height="13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                        </div>
                        <div class="table__th"></div>
                    </div>
                </div>
                <div class="table__tbody">
                    <div class="table__tr" v-for="configuration in configurations" :key="'tbody-' + configuration.id">
                        <div class="table__td">{{ configuration.account.name }}</div>
                        <div class="table__td">{{ configuration.account.company }}</div>
                        <div class="table__td"><strong>{{ configuration.style }}:</strong> {{ configuration.name }}</div>
                        <div class="table__td" style="display: flex; justify-content: space-between;">
                            <!--<strong class="u-text-success" v-if="configuration.status.id === 1">{{ configuration.status.label }}</strong>
                            <strong v-else-if="configuration.status.id === 10">{{ configuration.status.label }}</strong>
                            <strong v-else>{{ configuration.status.label }}</strong>-->

                            <select class="form-control form-control--small" v-model="configuration.status.id">
                                <option v-bind:value="option.id" :disabled="option.id < configuration.status.id" v-for="option in status" :key="option.id">{{ option.label }}</option>
                            </select>

                            <app-button class="c-btn c-btn--success c-btn--square c-btn--small" v-on:click.native.prevent="updateStatus" :data-id="configuration.id" :data-status-id="configuration.status.id">
                                <svg width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z"></path>
                                </svg>
                            </app-button>
                        </div>
                        <div class="table__td">{{ currency(configuration.price) }}</div>
                        <div class="table__td">{{ configuration.created.friendly }}</div>
                        <div class="table__td || c-btn-group c-btn-group--stacked || u-text-right">
                            <router-link :to="'/admin/configuration/' + configuration.id">Bekijk</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>

        <loading v-bind:nodes="configurations">Bezig met laden van de configuraties...</loading>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import AppButton from '../components/app/AppButton.vue';
    import AppIntro from '../components/app/AppIntro.vue';
    import Loading from '../components/Loading.vue';
    import currency from '../helpers/currency';
    import { configurationService } from '../services';

    export default {
        components: { AppButton, AppIntro, Loading },
        data () {
            return {
                sorting: false,
                order: {
                    name: false,
                    style: false,
                    status: false,
                    price: false,
                    date: 'desc',
                },
            };
        },
        computed: {
            ...mapState({
                configurations: (state) => state.configuration.list,
                status: (state) => state.configuration.status,
            }),
        },
        methods: {
            currency (price) {
                return currency(price);
            },
            updateStatus (e) {
                if (e.target.dataset.statusId && e.target.dataset.id)
                {
                    return configurationService
                        .updateStatus(e.target.dataset.id, { status: e.target.dataset.statusId })
                        .then(() => {
                            this.$store.dispatch('configuration/list');
                            this.$store.dispatch('alert/success', 'Configuratie is aangepast');

                            setTimeout(() => {
                                this.$store.dispatch('alert/clear');
                            }, 1500);
                        }).catch((error) => {
                            this.loading = false;
                            this.$store.dispatch('alert/error', error);
                        });
                }

                return false;
            },
            async orderTable (type) {
                // toggle the order
                switch (this.order[type])
                {
                    case 'asc':
                        this.order[type] = 'desc';
                        break;
                    default:
                    case 'desc':
                        this.order[type] = 'asc';
                        break;
                }

                // set sorting
                this.sorting = true;

                // order the list
                await this.$store.dispatch('configuration/list', { order: `${type}, ${this.order[type]}` });

                // reset sorting
                this.sorting = false;
            },
        },
        async created () {
            await this.$store.dispatch('configuration/list');
            await this.$store.dispatch('configuration/status');
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    .form-control--small {
        height: rem(30);
        padding: rem(3);
        font-size: rem(16);
        width: 75%;

        option:disabled {
            color: $text-muted;
        }
    }

    .table {
        @include media-breakpoint-only(sm) {
            grid-template-columns: rem(150) 1fr;
            margin: 0;
            max-width: 100%;
        }

        &__tr {
            @include media-breakpoint-up(lg) {
                grid-template-columns: 1fr 1fr 1.75fr 1.25fr 1fr 1fr rem(100);
            }
        }
    }
</style>
